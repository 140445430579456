import React from "react";
import SEO from "../components/seo";

const PrivacyPolicy = props => {
    return (
        <>
            <SEO title="Privacy Policy" description={"We will never sell your personal data to anyone."}/>
            <section className={"privacy-policy"}>
                <div>
                    <h1>Privacy Policy</h1>
                    <p>
                        Your privacy is critically important to us. As owner and operators of Scrum Mate we, Adaptive Consulting Ltd. have a
                        few fundamental principles:
                    </p>
                    <ul>
                        <li>We don't ask you for personal information unless we truly need it.</li>
                        <li>
                            We don't share your personal information with anyone except to comply with the law, develop our products, or
                            protect our rights.
                        </li>
                        <li>
                            We don't store personal information on our servers unless required for the on-going operation of one of our
                            services.
                        </li>
                    </ul>
                    <p>
                        If you have questions about deleting or correcting your personal data, please contact our support team at
                        help@scrummate.com.
                    </p>
                </div>
                <div>
                    <h2>Definitions</h2>
                    <p>
                        This Privacy Policy relates to information collected by Adaptive Consulting Inc. through Scrum Mate website and
                        service (referred to in this Privacy Policy as "Scrum Mate" "we" or "us" or "our".
                    </p>
                    <p>
                        "You" or "your" refers to the individual who uses this website, features, and services, or accesses available
                        information, or engages with us directly by email, in-app chat or telephone.
                    </p>
                    <p>
                        As used in this Privacy Policy, the terms "using" and "processing" information include using cookies on a computer,
                        subjecting the information to statistical or other analysis and using or handling information in any way, including,
                        but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing and
                        transferring information within our organization.
                    </p>
                </div>
                <div>
                    <h2>Information we collect</h2>
                    <h3>Account information</h3>
                    <p>
                        Scrum Mate obtains information about individuals when you visit this website or contact us directly about becoming a
                        customer or obtaining other information, when you register for a new account.
                    </p>
                    <p>
                        When you register for a new account, you become a data controller responsible for everything that is stored under
                        that account, as well as for management and lawful processing of personal data, including personal data of any users
                        you invite.
                    </p>
                    <p>
                        During registration we collect some personal information which includes your email address, name and IP address of
                        the computer you are connecting from, the website you are coming from and information about the referring search
                        engine phrase or marketing campaign details. We need this information to provide the service to you.
                    </p>
                    <p>
                        When you invite others to join your organization on Scrum Mate, we send an invitation email to the email addresses
                        and their first name you provided. The information you provide is stored and used to personalize and send the
                        invitation email to the invitee.
                    </p>
                    <p>
                        Organization owners and administrators have access to all information about people invited to their organization on
                        the "Users" page, where it can be corrected or removed if needed. Organization owners can't delete the data of
                        invited users. Only the person connected to an individual account can delete their own data from Scrum Mate.
                    </p>
                    <h3>Content that you provide</h3>
                    <p>
                        As the core functionality of the Scrum Mate service, we store and process the content you provide and make it
                        accessible to you and other people that you've shared it with. Examples include names of the boards you create, the
                        content you put on stories and tasks, files you attach and comments you make.
                    </p>
                    <p>
                        We do not pre-screen or claim any rights of ownership to such content, but it's your responsibility that it's legal
                        for you to post it. You retain all rights to the data you provide, even though this data may be sent and stored
                        using the hardware, software, storage, networking and related technologies that belong to third-party vendors.
                    </p>
                    <p>
                        Please bear in mind, that organization owners and administrators can limit or revoke your access to the content
                        you've provided, and you may not be able to get it back. Also, if the provided content includes personal data of
                        others, as a data controller you or your account owners are solely responsible for the conformance with any
                        applicable privacy laws and regulations.
                    </p>
                    <p>
                        As long as you have access to it, you can usually update and delete your content through interacting with our
                        service, usually in a manner similar to the one you've originally used to post the content.{" "}
                    </p>
                    <p>
                        Other users of organization with access to the same workspaces (boards or backlogs) may also be given the option to
                        modify or delete the content you have provided, given they have sufficient access permissions.
                    </p>
                    <p>
                        Organization owners and administrators can always gain access to, modify, and delete content posted on the account.
                    </p>
                    <h3>Support enquiries and related information</h3>
                    <p>We store and process any support enquiries you make, as well as any other communication you make with us.</p>
                    <p>
                        We understand and preserve the privacy of such enquiries, but also reserve the right to publish them after
                        sufficient anonymization on public channels such as social feeds or our "Support" pages in order to gather feedback
                        or facilitate resolution of similar issues by other users.
                    </p>
                    <h3>Payment and billing information</h3>
                    <p>
                        When you subscribe to a Scrum Mate plan, our third-party payment processors will collect and store your billing
                        address and credit card information.{" "}
                    </p>
                    <p>
                        We receive and process some of the information you provide to the payment processor, i.e. the country of credit card
                        issuance, card's expiration date and the billing address you have entered.
                    </p>
                    <p>
                        We may use it i.e. for credit card fraud screening, or to assign the payment to an appropriate source country for EU
                        VAT purposes.
                    </p>
                    <p>On no occasion we store or have access to the full credit card number you have provided.</p>
                    <p>
                        To issue tax invoices for the service, we need to store and process your invoicing details. You can access and
                        modify this information when needed on the "Subscription and payment" section on settings page.
                    </p>
                    <h3>Behavioral data</h3>
                    <p>
                        Scrum Mate use FullStory's (
                        <a href="https://www.fullstory.com" target="_blank" rel="noopener noreferrer">
                            https://www.fullstory.com
                        </a>
                        ), Amplitude's (
                        <a href="https://amplitude.com" target="_blank" rel="noopener noreferrer">
                            https://amplitude.com
                        </a>
                        ) and Intercom's (
                        <a href="https://www.intercom.com" target="_blank" rel="noopener noreferrer">
                            https://www.intercom.com
                        </a>
                        ) service to collect behavioral patterns across the application pages.{" "}
                    </p>
                    <p>
                        During the very first session on Scrum Mate web app, we collect and we may analyze your interactions with Scrum Mate
                        and we also have information about the data you entered when using the service (detailed behavioral data). The sole
                        purpose of collecting behavioral data is to improve our onboarding experience and shorten the learning time for new
                        users.
                    </p>
                    <ul>
                        <li>We collect detailed behavioral data during the first visit only after activation, and never again.</li>
                        <li>
                            Once you finished your first session (closed the browser window) after activating your new Scrum Mate account,
                            detailed behavioral data won't be collected anymore.
                        </li>
                        <li>All data will be deleted after 28 days.</li>
                        <li>
                            You may opt-out from having FullStory collect your information when visiting Scrum Mate at any time by using a
                            ‘Do Not Track' header. You can also opt-out by{" "}
                            <a href="https://www.fullstory.com/optout/" target="_blank" rel="noopener noreferrer">
                                clicking here
                            </a>
                            .
                        </li>
                    </ul>
                    <p>
                        After the very first session, we still collect statistical data about what features of Scrum Mate you use, but we
                        collect no information about the data you entered when using these features.
                    </p>
                    <p>
                        Both FullStory, Amplitude and Scrum Mate teams take a number of steps to ensure your privacy is respected. You can
                        learn about FullStory's privacy policy{" "}
                        <a href="https://www.fullstory.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
                            here
                        </a>
                        , Amplitude's privacy policy{" "}
                        <a href="https://amplitude.com/privacy" target="_blank" rel="noopener noreferrer">
                            here{" "}
                        </a>
                        and Intercom's privacy policy{" "}
                        <a href="https://www.intercom.com/terms-and-policies#privacy" target="_blank" rel="noopener noreferrer">
                            here
                        </a>
                        .
                    </p>
                    <p>
                        When you use Scrum Mate, our servers automatically record information ("log data"), including information that your
                        browser sends whenever you visit our Website. This log data may include your web address you came from or are going
                        to, your device model, operating system, browser type, unique device identifier, IP address, mobile network carrier,
                        and time zone or location. Whether we collect some or all of this information often depends on what type of device
                        you're using and its settings. For example, different types of information are available depending on whether you're
                        using a Mac or a PC, or an iPhone or an Android phone.{" "}
                    </p>
                    <p>
                        To learn more about what information your device makes available to us, please check the policies of your device
                        manufacturer or software provider.
                    </p>
                    <h3>Using Cookies</h3>
                    <p>
                        If your browser is configured to accept cookies, we may collect non-personally identifiable information passively
                        using "cookies" and "page tags".
                    </p>
                    <p>
                        "Cookies" are small text files that can be placed on your computer or mobile device in order to identify your Web
                        browser and the activities of your computer on the Scrum Mate Service and other websites.
                    </p>
                    <p>
                        "Page tags," also known as web beacons or gif tags, are a web technology used to help track website or email usage
                        information, such as how many times a specific page or email has been viewed. Page tags are invisible to you, and
                        any portion of the Scrum Mate service, including advertisements, or e-mail sent on our behalf, may contain page
                        tags.
                    </p>
                    <p>
                        We also use cookies to personalize your experience on the Scrum Mate websites (such as dynamically generating
                        content on webpages specifically designed for you), to assist you in using the Scrum Mate service (such as
                        remembering which panels were open on your boards and backlogs, what custom colors you use to mark tags), to allow
                        us to statistically monitor how you are using the Service so that we can improve our offerings, and to target
                        certain advertisements to your browser which may be of interest to you or to determine the popularity of certain
                        content. By using cookies and page tags together, we are able to improve the Service and measure the effectiveness
                        of our advertising and marketing campaigns.
                    </p>
                    <p>
                        Please be aware that this cookie policy does not govern the use of third-party websites or services or providers of
                        third-party services.
                    </p>
                    <p>
                        You do not have to accept cookies to use the Scrum Mate Service. If you reject cookies, certain features or
                        resources of the Scrum Mate Service may not work properly or at all and you may have a degraded experience.
                    </p>
                    <p>
                        To learn more about cookies; how to control, disable or delete them, please visit{" "}
                        <a href="http://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">
                            http://www.aboutcookies.org
                        </a>
                        . Some third party advertising networks, like Google, allow you to opt out of or customize preferences associated
                        with your internet browsing. For more information on how Google lets you customize these preferences,{" "}
                        <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener noreferrer">
                            see their documentation
                        </a>
                        .
                    </p>
                    <h3>Children</h3>
                    <p>
                        Scrum Mate is not directed to children under (13) years of age and we do not knowingly collect personal information
                        from children under 13. If we discover that a child under 13 has provided us with personal information, we will
                        promptly delete such personal information from our systems.
                    </p>
                </div>
                <div>
                    <h2>Information we receive from other sources</h2>
                    <p>We receive information about you from other Service users, from third-party services. </p>
                    <p>
                        Other users of our service may provide information about you when they submit content through the service. For
                        example, information may be submitted about you by other user in comments, user story and task descriptions.
                    </p>
                    <p>
                        We receive information about you when you or your organization admin integrate or link a third-party service with
                        our service. For example, if you create an account or log into the Scrum Mate using your Google credentials, we
                        receive your name and email address as permitted by your Google profile settings in order to authenticate you.{" "}
                    </p>
                    <p>
                        The information we receive when you link or integrate our service with a third-party service depends on the
                        settings, permissions and privacy policy controlled by that third-party service. You should always check the privacy
                        settings and notices in these third-party services to understand what data may be disclosed to us or shared with
                        other applications.
                    </p>
                    <p>
                        You or your organization owner or administrator may also integrate Scrum Mate with other services you use, such as
                        to allow you to access, store, share and edit certain content from a third-party through Scrum Mate.
                    </p>
                    <p>
                        We receive information about you when you sign up or when your team member invites you to Scrum Mate via our
                        customer success management system, Intercom. Intercom collects and display publicly available information about you
                        from social networks, such as Facebook, LinkedIn, Twitter and others. You can learn more about Intercom's privacy
                        policy{" "}
                        <a href="https://www.intercom.com/terms-and-policies#privacy" target="_blank" rel="noopener noreferrer">
                            here
                        </a>
                        .
                    </p>
                </div>
                <div>
                    <h2>How We Use Your Information</h2>
                    <p>We may use information that we collect about you, including personal information, to:</p>
                    <h3>Provide the Scrum Mate Service</h3>
                    <p>
                        We will use your information to provide our services to you; to manage your projects; to manage your account; to
                        respond to your inquiries; and for other customer service and support purposes.{" "}
                    </p>
                    <h3>Understand and improve our products</h3>
                    <p>
                        We will perform research and analysis about your use of, or interest in, our products, services, or content. We do
                        this to help make our products better and to develop new products.
                    </p>
                    <h3>Communications related to provide our service</h3>
                    <p>
                        We may send you service and administrative emails to ensure the service is working properly. We will also email you
                        regarding your personal to-dos. These messages are considered part of the service and you may not opt out of these
                        messages.
                    </p>
                    <h3>Promotional</h3>
                    <p>
                        Subject to your opt-out preference, we may send you emails about new product features or other news about Scrum Mate
                        or on topics we think would be relevant to you. You may opt out of receiving these communications at any time. Visit
                        the Your Rights and Choices section below.{" "}
                    </p>
                    <h3>Responding to your requests</h3>
                    <p>We will also use your information to respond to your questions or comments.</p>
                    <h3>Administrative</h3>
                    <p>
                        We may contact you to inform you about changes in our services, our service offering and other important service
                        related notices, such as changes to the Policy or about security or fraud notices.
                    </p>
                    <h3>Protecting Rights and Interests</h3>
                    <p>
                        We will use your information to protect our rights and interests as well as the rights and interests of our users
                        and any other person, as well as to enforce this Policy or our Terms of Service.
                    </p>
                    <h3>Legal Compliance</h3>
                    <p>
                        We may use your information to comply with applicable legal or regulatory obligations, including informal requests
                        from law enforcement or other governmental authorities.
                    </p>
                </div>
                <div>
                    <h2>Legal Basis for Processing Your Information</h2>
                    <p>We rely on the following legal grounds to process your personal information:</p>
                    <h3>Consent</h3>
                    <p>
                        We may use your personal information as described in this Policy subject to your consent. To withdraw your consent,
                        please contact us at help@scrummate.com. You may also refrain from providing, or withdraw, your consent for cookies.
                        Please see Your Rights and Choices below for more information on opt-outs.
                    </p>
                    <h3>Performance of a contract</h3>
                    <p>We may need to collect and use your personal information to perform our contractual obligations.</p>
                    <h3>Legitimate Interests</h3>
                    <p>
                        We may use your personal information for our legitimate interests to provide and improve our services. We process
                        information on behalf of our customers who have legitimate interests in operating their businesses. We may use
                        technical information as described in this Policy and use personal information for our marketing purposes consistent
                        with our legitimate interests and any choices that we offer or consents that may be required under applicable law.
                    </p>
                </div>
                <div>
                    <h2>Your rights and choices</h2>
                    <p>
                        You have certain choices available to you when it comes to your information. Below is a summary of those choices,
                        how to exercise them and any limitations.
                    </p>
                    <h3>Request a copy of your information</h3>
                    <p>
                        You have the right to request a copy of your information in a structured, electronic format. You may contact us as
                        at help@scrummate.com to request a copy of your information. Users with the appropriate access rights can export
                        content (such as user stories and tasks) from Scrum Mate to a Microsoft Excel spreadsheet.
                    </p>
                    <h3>Access and update your information</h3>
                    <p>
                        Settings page in Scrum Mate gives you the ability to access and update your account information, such as name,
                        profile photo and company you work for.{" "}
                    </p>
                    <h3>Delete or anonymize your information </h3>
                    <p>
                        Upon your request, our support team anonymize all stored personal data about you, so it will be impossible to
                        identify you and to connect any information you provided with you.
                    </p>
                    <p>
                        Please note, that we may need to retain certain information for Tax record keeping purposes, to complete
                        transactions or to comply with our legal obligations.{" "}
                    </p>
                    <p>
                        Regarding content you provided, the "delete" option on the content you or others have provided to the service, the
                        underlying data will usually be only marked as deleted. It may remain accessible to you and others, and subject to
                        recovery.
                    </p>
                    <h3>Request that we stop using your information</h3>
                    <p>
                        In some cases, you may ask us to stop accessing, storing, using and otherwise processing your information where you
                        believe we don't have the appropriate rights to do so. For example, if you believe a Scrum Mate account was created
                        for you without your permission or you are no longer an active user, you can request that we anonymize your account
                        as provided in this policy.{" "}
                    </p>
                    <p>When you make such requests, we may need time to investigate and facilitate your request. </p>
                    <h3>Stop receiving emails</h3>
                    <p>
                        We usually don't send promotional emails. You may opt out of receiving emails from us by using the unsubscribe link
                        within each email or by contacting our support team.
                    </p>
                </div>
                <div>
                    <h2>How we store and secure information we collect</h2>
                    <h3>International data transfers</h3>
                    <p>
                        We operate globally and primarily store and process your information on a cloud infrastructure physically located in
                        the United States and the European Economic Area (EEA). By using the service, you understand and accept that your
                        personal data may be stored and transferred internationally.
                    </p>
                    <p>
                        Whenever we appoint international data sub-processor or a third-party service, we take steps to ensure adequate
                        protection of your rights and ensure its conformance to the industry best standards and practices. This includes,
                        where applicable, conformance with the EU-US Privacy Shield Program and Model Contractual Clauses.
                    </p>
                    <h3>Data security</h3>
                    <p>
                        Scrum Mate and its third-party service providers have implemented and maintain commercially reasonable technical and
                        organizational security measures designed to meet the reliability and confidentiality of data.
                    </p>
                    <p>
                        However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures to access
                        your data for improper purposes. You acknowledge that there are risks inherent in Internet connectivity that could
                        result in the loss of your privacy, confidential information and property.
                    </p>
                    <h3>Appointed sub-processors</h3>
                    <p>We currently use the following third-party suppliers to assist in connection with the services.</p>
                    <ul>
                        <li>
                            Microsoft Azure Cloud (
                            <a href="https://azure.microsoft.com/en-us/" target="_blank" rel="noopener noreferrer">
                                https://azure.microsoft.com/en-us/
                            </a>
                            )
                        </li>
                        <li>
                            Amazon Web Services (AWS) (
                            <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">
                                https://aws.amazon.com/
                            </a>
                            )
                        </li>
                        <li>
                            Google Analytics (
                            <a href="https://www.google.com/analytics/" target="_blank" rel="noopener noreferrer">
                                https://www.google.com/analytics/
                            </a>
                            )
                        </li>
                        <li>
                            Intercom (
                            <a href="https://www.intercom.com/" target="_blank" rel="noopener noreferrer">
                                https://www.intercom.com/
                            </a>
                            )
                        </li>
                        <li>
                            Taxamo (
                            <a href="https://www.taxamo.com/" target="_blank" rel="noopener noreferrer">
                                https://www.taxamo.com/
                            </a>
                            )
                        </li>
                        <li>
                            FullStory (
                            <a href="https://www.fullstory.com/" target="_blank" rel="noopener noreferrer">
                                https://www.fullstory.com/
                            </a>
                            )
                        </li>
                        <li>
                            Amplitude (
                            <a href="https://amplitude.com/" target="_blank" rel="noopener noreferrer">
                                https://amplitude.com/
                            </a>
                            )
                        </li>
                        <li>
                            Braintree Payments (
                            <a href="https://www.braintreepayments.com/" target="_blank" rel="noopener noreferrer">
                                https://www.braintreepayments.com/
                            </a>
                            )
                        </li>
                        <li>
                            Postmark (
                            <a href="https://postmarkapp.com/" target="_blank" rel="noopener noreferrer">
                                https://postmarkapp.com/
                            </a>
                            )
                        </li>
                    </ul>
                </div>
                <div>
                    <h2>Changes to This Privacy Policy</h2>
                    <p>
                        We may need to update this policy from time to time. We encourage you to periodically review this page for the
                        latest information on our privacy practices.
                    </p>
                    <p>This Policy was last updated on May 4, 2020.</p>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;
